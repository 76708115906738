import { StoreDoc } from '@gooduncles/gu-app-schema';
import { DeliverySpotDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, Popconfirm, notification } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { FC, useCallback, useMemo, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { deleteDeliverySpot, getStores, updateDeliverySpot, updateStore } from 'src/lib/4/firebase-short-cut';
import {
  getCellStyleForLatestOrderAt,
  onCellValueChangedWithUpdate,
  onValueSetterWithValidation,
  stringDateFormatter,
} from 'src/lib/6/ag-grid-util';

import { selectValidPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

import AgCellToggleEditor from 'src/atomic-components/atoms/AgCellToggleEditor/AgCellToggleEditor';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';
import SelectableCell from 'src/components/Common/SelectableCell/SelectableCell';

const firebaseManager = FirebaseManager.getInstance();

export type DeliverySpotWithShopRowData = DeliverySpotDoc & {
  stores: StoreDoc[];
};

/**
 * 배송지 삭제 버튼
 * : 배송지 삭제시 해당 배송지를 사용하는 매장의 배송지를 'unregistered'로 변경해야한다.
 */
const DeleteDeliverySpotButton: FC<{ spotId: string }> = ({ spotId }) => {
  const [loading, setLoading] = useState(false);
  const onDeleteDeliverySpot = useCallback(async () => {
    setLoading(true);
    try {
      const stores = await getStores([['deliverySpotId', '==', spotId]]);
      firebaseManager.batchStart();
      for (const store of stores) {
        await updateStore(store._id, { deliverySpotId: 'unregistered' }, true);
      }
      await deleteDeliverySpot(spotId, true);
      await firebaseManager.batchEnd();
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '배송지 삭제에 실패했습니다.',
        description,
      });
    }
    setLoading(false);
  }, [spotId]);

  return (
    <Popconfirm
      title='선택한 배송지점을 삭제합니다.'
      onConfirm={onDeleteDeliverySpot}
      okButtonProps={{
        loading,
      }}>
      <Button danger>삭제</Button>
    </Popconfirm>
  );
};

const onCellValueChanged = onCellValueChangedWithUpdate<DeliverySpotWithShopRowData>('배송지 관리');
const deliverySpotPath = 'deliverySpot';

const defaultColDef: ColDef<DeliverySpotWithShopRowData> = {
  sortable: true,
  resizable: true,
  filter: true,
};
const DeliverySpotWithShopTable: FC<{ rowData: DeliverySpotWithShopRowData[] }> = ({ rowData }) => {
  const partnersUsers = useAppSelector(selectValidPartnersUsers);
  const partnerList: BaseOptionType[] = useMemo(
    () => [
      ...partnersUsers.map((user) => ({
        label: `${user.deliveryDriverStatus.isActive ? '🟢' : '🔴'} ${user.name} - ${
          user.deliveryDriverStatus.sortKey
        }`,
        value: user._id,
        sortKey: user.deliveryDriverStatus.sortKey,
      })),
      {
        label: '⚪️ 미지정',
        value: 'undefined',
        sortKey: 9999,
      },
    ],
    [partnersUsers]
  );

  const onUpdatePartner = useCallback(
    (spotId: string) => async (partnerId: string) => {
      try {
        await updateDeliverySpot(spotId, { partnerId });
      } catch (error) {
        console.error(error);
        const description = errorObjectToString(error);
        notification.error({
          message: '담당 파트너 변경에 실패했습니다.',
          description,
        });
      }
    },
    []
  );

  const columnDefs: ColDef<DeliverySpotWithShopRowData>[] = useMemo(
    () => [
      {
        field: '_id',
        hide: true,
      },
      {
        width: 280,
        field: 'title',
        headerName: '배송지명',
        valueFormatter: (params) => {
          const length = params?.data?.stores.length ?? 0;
          return `${params.value} (${length})`;
        },
        cellRenderer: 'agGroupCellRenderer',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChanged(params, deliverySpotPath, params.data._id),
      },
      {
        width: 88,
        field: 'sortKey',
        headerName: '순서',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id'], undefined, { type: 'number' }),
        onCellValueChanged: (params) => onCellValueChanged(params, deliverySpotPath, params.data._id),
      },
      {
        width: 240,
        field: 'partnerId',
        headerName: '담당 파트너',
        cellRenderer: (params: any) => {
          const partnerId = params.value;
          const data = params.data as DeliverySpotWithShopRowData;
          const sortedList = partnerList.sort((a, b) => a.sortKey - b.sortKey);
          return <SelectableCell options={sortedList} value={partnerId} onChange={onUpdatePartner(data._id)} />;
        },
        filterParams: {
          valueFormatter: (params: any) => {
            const value = params.value;
            return partnerList.find((option) => option.value === value)?.label ?? value;
          },
        },
      },
      {
        width: 280,
        field: 'roadAddress',
        headerName: '도로명 주소',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChanged(params, deliverySpotPath, params.data._id),
      },
      {
        width: 240,
        field: 'jibunAddress',
        headerName: '지번 주소',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChanged(params, deliverySpotPath, params.data._id),
      },
      {
        width: 114,
        field: 'lat',
        headerName: '위도',
        editable: true,
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id'], undefined, { type: 'number' }),
        onCellValueChanged: (params) => onCellValueChanged(params, deliverySpotPath, params.data._id),
      },
      {
        width: 114,
        field: 'lng',
        headerName: '경도',
        editable: true,
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id'], undefined, { type: 'number' }),
        onCellValueChanged: (params) => onCellValueChanged(params, deliverySpotPath, params.data._id),
      },
      {
        width: 114,
        field: 'hidden',
        headerName: '숨김',
        cellRenderer: (params: any) => {
          return (
            <AgCellToggleEditor
              id={params.data._id}
              value={params.value}
              field={params.colDef.field}
              onChange={updateDeliverySpot}
            />
          );
        },
      },
      {
        width: 96,
        field: '_id',
        headerName: '삭제',
        cellRenderer: (params: any) => {
          const spotId = params.value;
          return <DeleteDeliverySpotButton spotId={spotId} />;
        },
      },
      {
        headerName: 'raw data',
        hide: true,
        cellRenderer: DownloadRawDataCell,
      },
    ],
    [onUpdatePartner, partnerList]
  );

  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: ColDef<StoreDoc>[] = [
      {
        field: '_id',
        hide: true,
      },
      {
        width: 320,
        field: 'storeNickname',
        headerName: '매장명',
      },
      {
        field: 'latestOrderAt',
        headerName: '최근 주문일',
        width: 120,
        cellStyle: getCellStyleForLatestOrderAt,
        valueFormatter: stringDateFormatter,
      },
      {
        width: 240,
        field: 'address',
        headerName: '주소',
      },
      {
        field: 'addressDetail',
        headerName: '상세주소',
      },
      {
        width: 100,
        field: 'sido',
        headerName: '시도',
      },
      {
        width: 120,
        field: 'sigungu',
        headerName: '시군구',
      },
      {
        width: 120,
        field: 'lat',
        headerName: '위도',
      },
      {
        width: 120,
        field: 'lng',
        headerName: '경도',
      },
    ];

    return {
      refreshStrategy: 'rows',
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
        enableCellChangeFlash: true,
      },
      getDetailRowData: (params: any) => {
        const row = params.data as DeliverySpotWithShopRowData;
        const data = row.stores;
        params.successCallback(data);
      },
    };
  }, []);

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // 그룹 노드
        masterDetail={true}
        keepDetailRows={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
      />
    </div>
  );
};

export default DeliverySpotWithShopTable;
